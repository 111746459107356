module.exports = [{
      plugin: require('/workspace/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145172305-1"},
    },{
      plugin: require('/workspace/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
